import Sheet from 'react-modal-sheet';
import close from '../assets/images/icons/icon-close-green.png';
import {Link} from "react-router-dom";
import Button from "./Button.tsx";
import '../scss/yettel-petak-award-modal.scss';

interface Props {
  isPopupOpen: boolean;
  setPopupOpen: (open: boolean) => void;
  text: string;
  showSelectStore?: boolean;
  activationId?: number;
}

const YettelPetakScratchAward: React.FC<Props> = ({ isPopupOpen, setPopupOpen, text, showSelectStore, activationId }) => {
  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <Sheet isOpen={isPopupOpen} onClose={handleClose} className='award-modal'>
      <Sheet.Container>
        <Sheet.Header>
          <div className="d-flex justify-content-end pt-2">
            <img
              className="cursor-pointer"
              width={25}
              height={25}
              src={close}
              alt="close modal"
              onClick={handleClose}
            />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div className="award-text pb-3">
            <p>{text}</p>
          </div>
          {showSelectStore && (
            <div className="my-3 mb-3 select-store-button">
              <p className="award-text pb-3">Izaberite prodavnicu u kojoj želite podići nagradu.</p>
              <Link to={`/pregled-dobitaka${activationId && `?itemId=${activationId}`}`} onClick={handleClose}>
                <Button secondary={true} className="award-text">Izaberi prodavnicu</Button>
              </Link>
            </div>
          )}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={handleClose} />
    </Sheet>
  );
};

export default YettelPetakScratchAward;
