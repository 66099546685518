export const PROVISIONED = 'provisioned';
export const NOT_PROVISIONED = 'notprovisioned';
export const PENDING = 'pending';
export const UNSUCCESS = 'unsuccess';
export const USED = 'used';
export const UNUSED = 'unused';
export const EXPIRED = 'expired';

export const SCRATCH_CARD_WIDTH = 345;
export const SCRATCH_CARD_HEIGHT = 423;

export const SCRATCH_COUNTER_WIDTH = 345;
export const SCRATCH_COUNTER_HEIGHT = 392;

