import { useQuery } from '@tanstack/react-query';
import { ApiResponse, SingleOffer, Store } from '../types/types';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callPublicAPI } from '../utils/api';
import { STALE_TIME } from '../constants/staleTime';

export const usePartnerLocationsForOfferId = (offer: SingleOffer) => {
  const queryEnabled = offer.store_location_type !== 'online';

  return useQuery<ApiResponse<Store[]>>({
    queryKey: [QUERY_KEYS.GET_OFFER_PARTNER_LOCATION, offer.id],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        ad_id: offer.id,
        limit: '100'
      });

      const response = await callPublicAPI<Store[]>('GET', '/partner-locations', searchParams);

      return response;
    },
    staleTime: STALE_TIME.HOUR,
    enabled: queryEnabled
  });
};
