import { mapSingleOfferToAnalyticsObject, pushToAnalytics } from '../utils/analytics';
import { usePartnerLocationsForOfferId } from '../hooks/usePartnerLocationsForOfferId';
import { SingleOffer, YSH_GTM_EVENTS } from '../types/types';
import '../scss/offer-location-and-contact.scss';

interface Props {
  offer: SingleOffer;
}

const OfferLocationAndContact: React.FC<Props> = ({ offer }) => {
  const hasStoreLocation = offer.store?.lat && offer.store?.lng;
  const storeLocationLink =
    hasStoreLocation && `/near-by?lat=${offer.store?.lat}&lng=${offer.store?.lng}&partner=${offer.partner.id}`;

  const { data: partnerLocationData } = usePartnerLocationsForOfferId(offer);

  const { name, customer_contact_phone, web, customer_contact_email } = offer.partner;

  const showLocationInfo = partnerLocationData?.data?.length === 1 || false;
  const {
    name: storeName,
    address,
    saturday,
    sunday,
    work_days
  } = (showLocationInfo && partnerLocationData?.data[0]) || {};

  const sendContactClickToAnalytics = (type: 'phone' | 'email' | 'website') => {
    pushToAnalytics(YSH_GTM_EVENTS.COUPON_CONTACT_CLICK, {
      option_selected: type,
      coupon_details: [mapSingleOfferToAnalyticsObject(offer, 'Coupon details')]
    });
  };

  return (
    <ul className="offer-location-and-contact">
      <li>
        <article className="font-yettel">
          <span>
            {name} {showLocationInfo && `- ${storeName}`}
          </span>
          {showLocationInfo && (
            <>
              <br />
              <span>{address}</span>
            </>
          )}
        </article>
      </li>
      {hasStoreLocation && (
        <li>
          <span> Lokacije prodavnica</span>
          <span>
            <a className="text-decoration-underline" href={storeLocationLink.toString()}>
              Prikaži
            </a>
          </span>
        </li>
      )}
      {showLocationInfo && (
        <li>
          <span>Radno vreme</span>
          <span className="font-yettel text-end">
            Radni dan: {work_days} <br />
            {Boolean(saturday) && <span>Subota: {saturday}</span>}
            <br />
            {Boolean(sunday) && <span>Nedelja: {sunday}</span>}
          </span>
        </li>
      )}

      {Boolean(customer_contact_phone) && (
        <li className="contact">
          <span>Kontakt telefon</span>
          <a onClick={() => sendContactClickToAnalytics('phone')} href={`tel:${customer_contact_phone}`}>
            {customer_contact_phone}
          </a>
        </li>
      )}
      {Boolean(web) && (
        <li className="contact">
          <span>Web sajt</span>
          <a onClick={() => sendContactClickToAnalytics('website')} href={web} target="_blank">
            {web}
          </a>
        </li>
      )}
      {Boolean(customer_contact_email) && (
        <li className="contact">
          <span>Email</span>
          <a onClick={() => sendContactClickToAnalytics('email')} href={`emailto:${customer_contact_email}`}>
            {customer_contact_email}
          </a>
        </li>
      )}
    </ul>
  );
};

export default OfferLocationAndContact;
