import { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import wizard from "../assets/yettelPetak/wizard.png";
import Button from "./Button";
import { SESSION_STORAGE_KEYS } from "../constants/sessionStorageKeys";
import { UNUSED } from "../constants/scratchCard";
import YettelPetakScratchCardModal from "./YettelPetakScratchCardModal";
import { QUERY_KEYS } from "../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { ScratchOffer, ScratchOfferResponse, YSH_GTM_EVENTS } from "../types/types";
import { callPublicAPI } from "../utils/api";
import { isUserLoggedIn } from "../utils/auth";
import YettelPetakConsentModal from "./YettelPetakConsentModal";
import { pushToAnalytics } from "../utils/analytics";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";
import close from "../assets/images/icons/icon-close.svg";
import "../scss/yettel-petak-wizard-modal.scss";

const YettelPetakWizardModal: React.FC = () => {
  const isLoggedIn = isUserLoggedIn();
  const userMarketingConsent = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_DIRECT_MARKETING_CONSENTED);

  const [isWizardModalActive, setIsWizardModalActive] = useState(false);
  const [isConsentModalActive, setIsConsentModalActive] = useState(false);
  const [isScratchCardActive, setIsScratchCardActive] = useState(false);

  const { isSuccess, data: scratchOffer } = useQuery<ScratchOfferResponse>({
    queryKey: [QUERY_KEYS.GET_SCRATCH_OFFERS],
    retry: false,
    queryFn: async () => {
      const response = await callPublicAPI<ScratchOffer>("GET", "/scratch-offers");
      return response;
    },
    enabled: isLoggedIn,
  });

  const showScratchCardModal = isSuccess && scratchOffer && scratchOffer.data;

  useEffect(() => {
    if (
      scratchOffer?.data?.status === UNUSED &&
      sessionStorage.getItem(SESSION_STORAGE_KEYS.IS_WIZARD_YETTEL_PETAK_MODAL_SEEN) !== "true"
    ) {
      setIsWizardModalActive(true);
      pushToAnalytics(YSH_GTM_EVENTS.SCRATCH_AND_WIN_NOTIFICATION, {});
    }
  }, [scratchOffer]);

  const onCloseWizardYettelPetakPopup = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.IS_WIZARD_YETTEL_PETAK_MODAL_SEEN, "true");
    setIsWizardModalActive(false);
  };

  const onClickOpenScratch = () => {
    if (userMarketingConsent !== '0') {
      if (scratchOffer?.data?.status === UNUSED) {
        setIsWizardModalActive(false);
        setIsScratchCardActive(true);
      }
    } else {
      setIsWizardModalActive(false);
      setIsConsentModalActive(true);
    }
  };

  return (
    <>
      <Sheet isOpen={isWizardModalActive} onClose={onCloseWizardYettelPetakPopup} className="wizard-modal">
        <Sheet.Container>
          <Sheet.Header>
            <div className="d-flex justify-content-end pt-3">
              <img
                className="cursor-pointer"
                width={25}
                height={25}
                src={close}
                alt="close modal"
                onClick={onCloseWizardYettelPetakPopup}
              />
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <WizardContent onClickOpenScratch={onClickOpenScratch} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onCloseWizardYettelPetakPopup} />
      </Sheet>

      <YettelPetakConsentModal
        isModalActive={isConsentModalActive}
        setIsModalActive={setIsConsentModalActive}
        setIsWizardModalActive={setIsWizardModalActive}
        setIsScratchCardActive={setIsScratchCardActive}
      />

      {showScratchCardModal && (
        <YettelPetakScratchCardModal
          scratchOffer={scratchOffer.data}
          isModalActive={isScratchCardActive}
          setIsModalActive={setIsScratchCardActive}
        />
      )}
    </>
  );
};

interface WizardContentProps {
  onClickOpenScratch: () => void;
}

const WizardContent: React.FC<WizardContentProps> = ({ onClickOpenScratch }) => {
  return (
    <>
      <img className="yettel-petak-wizard-image" alt="scratch" src={wizard} onClick={onClickOpenScratch} />
      <Button className="mt-3" onClick={() => onClickOpenScratch()}>
        Ogrebi i osvoji
      </Button>
    </>
  );
};

export default YettelPetakWizardModal;
