import { useState } from 'react';
import { isFtthUser, isNonYettelUser, isRegisteredPrepaidUser, isYettelUser } from '../utils/user';
import Button from './Button';
import CheckMark from './Checkmark';
import PageTitle from './PageTitle';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { callPublicAPI } from '../utils/api';
import { UpdateConsentRequestData, UserConsentUpdated } from '../types/types';
import { useMutation } from '@tanstack/react-query';
import {
  persistEmailContactConsentInLocalStorage,
  persistPhoneContactConsentInLocalStorage,
  persistTermsAndConditionsAcceptedInLocalStorage,
  persistUserConsentMutationInLocalStorage
} from '../utils/auth';

const PrivacySettings = () => {
  const [searchParams] = useSearchParams();
  const callback = searchParams.get('callback');

  const navigate = useNavigate();

  const showMarketingConsent = isRegisteredPrepaidUser() || isYettelUser() || isNonYettelUser() || isFtthUser();
  const showPhoneAndEmailContactConsent = isNonYettelUser() || isFtthUser();

  const userTermsAccepted = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_TERMS_ACCEPTED);
  const userMarketingConsent = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_DIRECT_MARKETING_CONSENTED);
  const userPhoneConsent = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_PHONE_CONTACT_CONSENTED);
  const userEmailConsent = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_EMAIL_CONTACT_CONSENTED);

  const [termsAccepted, setTermsAccepted] = useState(userTermsAccepted === '1');
  const [marketingConsentGiven, setMarketingConsentGiven] = useState(userMarketingConsent === '1');
  const [phoneConsentGiven, setPhoneConsentGiven] = useState(userPhoneConsent === '1');
  const [emailConsentGiven, setEmailConsentGiven] = useState(userEmailConsent === '1');

  const giveConsent = useMutation({
    mutationFn: async () => {
      const data: UpdateConsentRequestData = {
        direct_marketing_consented: marketingConsentGiven ? 1 : 0,
        phone_contact_consented: !isYettelUser() ? (phoneConsentGiven ? 1 : 0) : undefined,
        email_contact_consented: !isYettelUser() ? (emailConsentGiven ? 1 : 0) : undefined,
        terms_and_conditions: termsAccepted ? 1 : 0
      };

      const response = await callPublicAPI<UserConsentUpdated>('POST', '/user-consents', null, data);
      return response.data;
    },
    onSuccess: data => {
      persistUserConsentMutationInLocalStorage(data);
      persistPhoneContactConsentInLocalStorage(phoneConsentGiven ? 1 : 0);
      persistEmailContactConsentInLocalStorage(emailConsentGiven ? 1 : 0);
      persistTermsAndConditionsAcceptedInLocalStorage(userTermsAccepted ? 1 : 0);
    },
    onError: () => {
      alert('Došlo je do greške prilikom promene politike privatnosti. Molimo Vas pokušajte ponovo kasnije.');
    },
    onSettled: () => {
      let callBackValue = callback;
      if (callBackValue === '/profil/privatnost' || window.location.pathname.includes('privatnost')) {
        callBackValue = '/';
      }

      navigate(callBackValue ?? window.location.pathname);
    }
  });

  return (
    <div className="font-yettel-light">
      <PageTitle className="mb-4">Privatnost podataka</PageTitle>

      {showMarketingConsent && (
        <section className="d-flex mb-4">
          <CheckMark checked={marketingConsentGiven} toggle={() => setMarketingConsentGiven(!marketingConsentGiven)} />
          <div className="ps-2">
            {isYettelUser() && (
              <>
                <p className="pb-2">
                  Pristajem da Yettel obrađuje moje podatke o ličnosti u cilju kreiranja i dostavljanja komercijalnih i
                  personalizovanih ponuda.
                </p>
                <p className="pb-4">
                  Davanje pristanka je potrebno ukoliko želite da na osnovu analize podataka o ličnosti kreiramo za vas
                  ponude kao što su: komercijalne i personalizovane ponude i popusti.
                </p>
              </>
            )}
            {isNonYettelUser() && (
              <>
                <p className="pb-2">
                  Pristajem da Yettel obrađuje moje podatke o ličnosti u cilju kreiranja i dostavljanja personalizovanih
                  ponuda na Yettel aplikaciji i "Yettel Shopping".
                </p>
                <p className="pb-4">
                  Davanje pristanka je potrebno ukoliko želite da na osnovu analize podataka o ličnosti kreiramo za vas
                  ponude kao što su: personalizovane ponude i popusti, kao i specijalne Yettel ponude.
                </p>
              </>
            )}

            <a
              className="d-block mb-3 font-yettel fz-18 text-decoration-underline"
              target="_blank"
              rel="noreferrer"
              href={`https://www.yettel.rs/sr/privatni/ponuda/aktuelno/yettel-shopping/${isYettelUser() ? 'pristanak-yettel' : 'pristanak-non-yettel'}/`}
            >
              Detaljnije o pristanku
            </a>

            <a
              className="font-yettel fz-18 text-decoration-underline"
              target="_blank"
              rel="noreferrer"
              href="https://www.yettel.rs/sr/o-yettelu/yettel-shopping-obavestenje-o-privatnosti/uvod"
            >
              Obaveštenje o privatnosti
            </a>
          </div>
        </section>
      )}

      {showPhoneAndEmailContactConsent && (
        <section className="mb-3">
          <div className="d-flex mb-3">
            <CheckMark checked={phoneConsentGiven} toggle={() => setPhoneConsentGiven(!phoneConsentGiven)} />
            <div className="ps-2">Pristajem da me za ponude kontaktirate putem telefona</div>
          </div>
          <div className="d-flex mb-3">
            <CheckMark checked={emailConsentGiven} toggle={() => setEmailConsentGiven(!emailConsentGiven)} />
            <div className="ps-2">Pristajem da me za ponude kontaktirate putem email-a</div>
          </div>
        </section>
      )}

      <section className="d-flex mb-3">
        <CheckMark checked={termsAccepted} toggle={() => setTermsAccepted(!termsAccepted)} />
        <div className="ps-2">
          Prihvatanje{' '}
          <a
            className="text-decoration-underline"
            target="_blank"
            rel="noreferrer"
            href="https://www.yettel.rs/sr/privatni/ponuda/aktuelno/yettel-shopping/uslovi-koriscenja/"
          >
            opštih uslova
          </a>{' '}
          korišćenja vam omogućava korišćenje Yettel Shopping i Yettel Petka
          {!termsAccepted && (
            <section className="mb-4 fz-14 color-error font-yettel">
              Prihvatanje opštih uslova korišćenja je neophodno da biste mogli da pristupite Yettel Shopping i Yettel
              Petku.
            </section>
          )}
        </div>
      </section>

      <Button onClick={() => giveConsent.mutate()} disabled={!termsAccepted}>
        Potvrdi
      </Button>
    </div>
  );
};

export default PrivacySettings;
